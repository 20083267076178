
export const tooltipConfig = {
  scale: 1.03,
  timing: 100,
  springConfig: {
    friction: 40,
    tension: 300 
  }
}

export const deleteDuration = 240;