import React from "react";

const Divider = ({provided, isDragging, handleItemClick, checkKeyDown, contrastDuringDragover}) => {
  return (
    <div
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      className="divider"
      onClick={handleItemClick}
      onKeyDown={checkKeyDown}
      style={{...provided.draggableProps.style}}>
      <span 
        className={`line ${isDragging ? " dragging" : ""}`}
        style={{
          borderColor: contrastDuringDragover ? '#a4a8b1' : '#e2e2e2' // gray70 / gray30
        }}
        />
    </div>
  );
};

export default Divider;
