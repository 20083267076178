import React, { useState, useEffect } from "react";
import { listSettingss } from "../graphql/queries";
import { createSettings } from "../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import WeekView from "./WeekView";
import YearView from "./YearView";
import AppNav from "./AppNav";
import KeyboardModal from "./KeyboardModal";

const ViewSelector = ({ user, signOut }) => {
  const [resolution, setResolution] = useState("week");
  const [sfxOn, setSfxOn] = useState(true);
  const [showKeyboardModal, setShowKeyboardModal] = useState(false);
  const [userSettings, setUserSettings] = useState(null);

  const [yearOffset, setYearOffset] = useState(0);

  const [weeksOffset, setWeeksOffset] = useState(0);

  const openKeyboardModal = () => {
    setShowKeyboardModal(true)
  }

  const closeKeyboardModal = () => {
    setShowKeyboardModal(true)
  }

  const loadSettings = async () => {
    if (!user) return;

    try {
      const itemData = await API.graphql(graphqlOperation(listSettingss));
      const settingsList = itemData.data.listSettingss.items;

      // If no settings exist, make them
      if (settingsList.length === 0) {
        try {
          const settings = {
            soundOn: true,
          };

          const { data } = await API.graphql(
            graphqlOperation(createSettings, { input: settings })
          );

          const returnedUserSettings = data.createSettings;
          setUserSettings(returnedUserSettings);
        } catch (error) {
          console.info("ERROR creating settings: ", error);
        }
      } else {
        const loadedUserSettings = itemData.data.listSettingss.items[0];
        setUserSettings(loadedUserSettings);
        setSfxOn(loadedUserSettings.soundOn);
      }
    } catch (error) {
      console.info("ERROR loading user settings: ", error);
    }
  };

  useEffect(() => {
    loadSettings();
    // eslint-disable-next-line
  }, [user]);

  return (
    <div className="App">
      <AppNav
        signOut={signOut}
        openKeyboardModal={openKeyboardModal}
        username={user.username}
        setSfxOn={setSfxOn}
        sfxOn={sfxOn}
        userSettings={userSettings}
        setUserSettings={setUserSettings}
        resolution={resolution}
        setResolution={setResolution}
        offset={resolution === "week" ? weeksOffset : yearOffset}
        setOffset={resolution === "week" ? setWeeksOffset : setYearOffset}
      />
      <KeyboardModal show={showKeyboardModal} onClose={closeKeyboardModal} />

      {resolution === "week" ? (
        <WeekView
          user={user}
          userSettings={userSettings}
          setUserSettings={setUserSettings}
          sfxOn={sfxOn}
          weeksOffset={weeksOffset}
          setWeeksOffset={setWeeksOffset}
        />
      ) : (
        <YearView user={user} yearOffset={yearOffset} />
      )}
    </div>
  );
};

export default ViewSelector;
