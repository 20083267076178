import React from 'react'

export const GripperSVG = ({ fill="black", className="", size="12", missed }) => {
  return (
    <svg
      width={size}
      viewBox="0 0 15 23"
      fill="none"
      className={`${className} ${missed && 'no-grip'}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2.5" cy="2.5" r="2.5" fill={fill} />
      <circle cx="2.5" cy="11.5" r="2.5" fill={fill} />
      <circle cx="2.5" cy="20.5" r="2.5" fill={fill} />
      <circle cx="12.5" cy="2.5" r="2.5" fill={fill} />
      <circle cx="12.5" cy="11.5" r="2.5" fill={fill} />
      <circle cx="12.5" cy="20.5" r="2.5" fill={fill} />
    </svg>
  );
}

export const TextSVG = ({ fill="black", className="", size="16", isDragging}) => {
  return (
    <svg
      width={size}
      viewBox="0 0 27 22"
      fill="none"
      style={{opacity: isDragging ? 0 : 1}}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="27" height="4" rx="2" fill={fill} />
      <rect y="9" width="18.9" height="4" rx="2" fill={fill} />
      <rect y="18" width="23.4" height="4" rx="2" fill={fill} />
    </svg>
  );
}

export const KeyboardSVG = ({ fill = "black", size = "17" }) => {
  return (
    <svg width={size} height="21" viewBox="0 0 36 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M30.122 2.03436H5.57822C3.11365 2.03436 1.11572 4.03229 1.11572 6.49686V15.4219C1.11572 17.8864 3.11365 19.8844 5.57822 19.8844H30.122C32.5865 19.8844 34.5845 17.8864 34.5845 15.4219V6.49686C34.5845 4.03229 32.5865 2.03436 30.122 2.03436Z" stroke={fill} strokeWidth="2.23125" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.0405 6.49689H12.2718" stroke={fill} strokeWidth="2.23125" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M23.4282 6.49689H25.6595" stroke={fill} strokeWidth="2.23125" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.7344 6.49689H18.9656" stroke={fill} strokeWidth="2.23125" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.69385 10.9594H8.9251" stroke={fill} strokeWidth="2.23125" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.3875 10.9594H15.6187" stroke={fill} strokeWidth="2.23125" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20.0813 10.9594H22.3125" stroke={fill} strokeWidth="2.23125" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M26.7749 10.9594H29.0062" stroke={fill} strokeWidth="2.23125" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.0405 15.4219H25.6593" stroke={fill} strokeWidth="2.23125" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="35.7" height="21" fill="transparent" />
        </clipPath>
      </defs>
    </svg>

  );
}

export const EllipsisSVG = ({ fill = "gray", size = "16" }) => {
  return (
    <svg
      width="6"
      height={size}
      viewBox="0 0 6 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="3.00022" cy="2.88889" r="2.88889" fill={fill} />
      <circle cx="3.00022" cy="13" r="2.88889" fill={fill} />
      <circle cx="3.00022" cy="23.1111" r="2.88889" fill={fill} />
    </svg>
  );
}

export const TrashSVG = ({ fill = "gray", size = "16" }) => {
  return (
    <svg width={size} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.25 6.75H33.75" stroke={fill} strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M21.375 2.25H14.625C14.0283 2.25 13.456 2.48705 13.034 2.90901C12.6121 3.33097 12.375 3.90326 12.375 4.5V6.75H23.625V4.5C23.625 3.90326 23.3879 3.33097 22.966 2.90901C22.544 2.48705 21.9717 2.25 21.375 2.25Z" stroke={fill} strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.625 25.875V14.625" stroke={fill} strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M21.375 25.875V14.625" stroke={fill} strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M28.2975 31.686C28.2508 32.2486 27.9943 32.7732 27.5789 33.1555C27.1635 33.5379 26.6196 33.7501 26.055 33.75H9.9465C9.38193 33.7501 8.83797 33.5379 8.42256 33.1555C8.00716 32.7732 7.75067 32.2486 7.704 31.686L5.625 6.75H30.375L28.2975 31.686Z" stroke={fill} strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export const AnimatedTrashSVG = ({ fill = "gray", size = "16", hovering }) => {
  return (
    <svg width={size} xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="-2 -10 18 28" className="delete-animation" fill={fill}>
      <path d="M10.5,2.3V1.5c0,0,0-0.1,0-0.1C10.5,0.6,9.8,0,9,0H6c0,0-0.1,0-0.1,0C5.1,0,4.5,0.7,4.5,1.5v0.8H0v1.5h15V2.3H10.5z M9,2.2  H6V1.5h3V2.2z" className={`lid ${hovering ? 'lid-hovered' : ''}`}/>
      <g className="can">
        <path d="M12.8,3.8v12c0,0,0,0,0,0.1c0,0.4-0.4,0.7-0.8,0.7H3c0,0,0,0-0.1,0c-0.4,0-0.7-0.4-0.7-0.8v-12H0.8v12   c0,0.6,0.2,1.2,0.7,1.6C1.8,17.8,2.4,18,3,18h9c0,0,0,0,0,0c1.2,0,2.2-1,2.2-2.2v-12H12.8z"/>
        <rect x="3.8" y="6" width="1.5" height="8.2"/>
        <rect x="6.8" y="6" width="1.5" height="8.2"/>
        <rect x="9.8" y="6" width="1.5" height="8.2"/>
      </g>
    </svg>
  );
}

export const DividerSVG = ({ fill = "gray", size = "14" }) => {
  return (
    <svg width={size} style={{marginLeft: -1}} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.002 24.248V15.998" stroke={fill} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.002 9.99805V1.74805" stroke={fill} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.25195 20.498L13.002 24.248L16.752 20.498" stroke={fill} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.25195 5.49805L13.002 1.74805L16.752 5.49805" stroke={fill} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1.75195 12.998H24.252" stroke={fill} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="2 4"/>
    </svg>
  );
}

export const ClockSVG = ({ fill = "gray", size = "14" }) => {
  return (
    <svg width={size} height={size} style={{marginLeft: -3, marginRight: -3}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12 12V9.14286" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12 12L15.571 15.5718" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export const CalTodaySVG = ({ fill = "gray", size = "14" }) => {
  const sharedStyle = {fill: "none", stroke: fill, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "1.5px"}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={size}>
      <path style={sharedStyle} d="M8.25,17.25h-6a1.5,1.5,0,0,1-1.5-1.5v-12a1.5,1.5,0,0,1,1.5-1.5h13.5a1.5,1.5,0,0,1,1.5,1.5v4.5"></path>
      <line style={sharedStyle} x1="0.75" y1="6.75" x2="17.25" y2="6.75"></line>
      <line style={sharedStyle} x1="5.25" y1="3.75" x2="5.25" y2="0.75"></line>
      <line style={sharedStyle} x1="12.75" y1="3.75" x2="12.75" y2="0.75"></line>
      <circle style={sharedStyle} cx="17.25" cy="17.25" r="6"></circle>
      <polyline style={sharedStyle} points="19.902 17.25 17.25 17.25 17.25 14.599"></polyline>
    </svg>
  );
}

export const CheckboxSVG = ({ fill = "gray", size = "14" }) => {
  return (
    <svg width={size} height={size} style={{marginLeft: -1}} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M18.1082 2.01124C18.3737 1.65807 18.3026 1.15655 17.9495 0.891063C17.5963 0.625574 17.0948 0.696653 16.8293 1.04982L9.185 11.2187L9.18495 11.2188C9.15083 11.2641 9.11335 11.2929 9.07979 11.3095C9.04675 11.3258 9.0154 11.3316 8.98683 11.3304C8.95819 11.3292 8.92578 11.3208 8.89162 11.3004C8.85694 11.2797 8.81942 11.2458 8.78696 11.1955L8.78694 11.1954L6.45369 7.57522C6.21433 7.20384 5.71924 7.09681 5.34786 7.33617C4.97648 7.57552 4.86946 8.07062 5.10881 8.442L7.44204 12.0622C7.60168 12.3099 7.81536 12.5213 8.07168 12.6743C8.32851 12.8276 8.61915 12.9166 8.92077 12.929C9.22247 12.9415 9.5198 12.8768 9.78861 12.7439C10.0569 12.6114 10.2864 12.4163 10.4638 12.1803L10.4639 12.1802L18.1082 2.01124ZM2.68398 3.74648C2.94184 3.48861 3.29158 3.34375 3.65625 3.34375H12.6875C13.1017 3.34375 13.4375 3.00796 13.4375 2.59375C13.4375 2.17953 13.1017 1.84375 12.6875 1.84375H3.65625C2.89375 1.84375 2.16249 2.14665 1.62332 2.68582C1.08415 3.22498 0.78125 3.95625 0.78125 4.71875V15.3437C0.78125 16.1062 1.08415 16.8375 1.62332 17.3767C2.16249 17.9158 2.89375 18.2187 3.65625 18.2187H14.2812C15.0437 18.2187 15.775 17.9158 16.3142 17.3767C16.8533 16.8375 17.1562 16.1062 17.1562 15.3437V8.4375C17.1562 8.02328 16.8205 7.6875 16.4062 7.6875C15.992 7.6875 15.6562 8.02328 15.6562 8.4375V15.3437C15.6562 15.7084 15.5114 16.0582 15.2535 16.316C14.9957 16.5739 14.6459 16.7187 14.2812 16.7187H3.65625C3.29158 16.7187 2.94184 16.5739 2.68398 16.316C2.42612 16.0582 2.28125 15.7084 2.28125 15.3437V4.71875C2.28125 4.35407 2.42612 4.00434 2.68398 3.74648Z" fill={fill}/>
    </svg>

  );
}

export const SignOutSVG = ({ fill = "gray", size = "14" }) => {
  return (
    <svg width={size} height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.5 12.004H24.25" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20.5 15.754L24.25 12.004L20.5 8.25403" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.7496 17.5V21C15.7667 21.3796 15.6328 21.7505 15.3772 22.0316C15.1216 22.3127 14.765 22.4811 14.3856 22.5H3.11256C2.73326 22.4808 2.37698 22.3123 2.12157 22.0312C1.86617 21.7501 1.73242 21.3794 1.74956 21V3C1.73216 2.62054 1.86581 2.24964 2.12127 1.96851C2.37673 1.68737 2.73317 1.51891 3.11256 1.5H14.3856C14.765 1.51891 15.1216 1.68734 15.3772 1.96845C15.6328 2.24955 15.7667 2.62044 15.7496 3V6.5" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export const SignificanceSVG = ({ fill = "gray", size = "14" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 15C15.866 15 19 11.866 19 8C19 4.13401 15.866 1 12 1C8.13401 1 5 4.13401 5 8C5 11.866 8.13401 15 12 15Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.21 13.8899L7 22.9999L12 19.9999L17 22.9999L15.79 13.8799"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export const ChevronSVG = ({ 
    left = false,
    animate = false,
    size = "11"
  }) => {
  return (
    <svg 
      className={`chevron-${left ? 'left' : 'right'} ${animate ? 'animate' : ''}`}
      width={size} viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 2L6 5.87879L2 10" stroke="#152F47" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export const ChevronDownSVG = ({ 
    size = "11",
    color= "black"
  }) => {
  return (
    <svg width={size} viewBox="0 0 10 7" fill="none" style={{marginLeft: 8}}>
      <path
        d="M1.00024 1.44379L4.81854 5.26208L8.41187 1.44379"
        stroke={color}
        strokeWidth="1.77"
        strokeLinecap="round"
      />
    </svg>
  );
}

export const SoundOnSVG = ({ fill = "gray", size = "21" }) => {
  return (
    <svg width={size} viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.1388 2.12226C17.8198 1.96282 17.4627 1.89537 17.1075 1.92747C16.7524 1.95958 16.4132 2.08997 16.1279 2.30402L7.67992 7.68002H3.83992C3.33071 7.68002 2.84235 7.88231 2.48228 8.24238C2.12221 8.60245 1.91992 9.09081 1.91992 9.60002V13.44C1.91992 13.9492 2.12221 14.4376 2.48228 14.7977C2.84235 15.1577 3.33071 15.36 3.83992 15.36H7.67992L16.1279 20.736C16.4132 20.95 16.7524 21.0802 17.1075 21.1123C17.4626 21.1443 17.8196 21.0768 18.1386 20.9173C18.4575 20.7579 18.7257 20.5127 18.9132 20.2094C19.1006 19.9061 19.1999 19.5566 19.1999 19.2V3.84002C19.2 3.4834 19.1008 3.1338 18.9134 2.8304C18.726 2.527 18.4578 2.28179 18.1388 2.12226Z" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M28.752 16.3201C29.618 14.9627 30.0781 13.3861 30.0781 11.7761C30.0781 10.166 29.618 8.5894 28.752 7.23206" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M23.7598 14.6445C24.4072 13.8476 24.7606 12.8521 24.7606 11.8253C24.7606 10.7985 24.4072 9.80304 23.7598 9.0061" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.67969 7.67999V15.36" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export const MuteSVG = ({ fill = "gray", size = "21" }) => {
  return (
    <svg width={size} height="23" viewBox="0 0 34 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.429 2.11862C18.1106 1.95945 17.7541 1.89212 17.3995 1.92416C17.045 1.95621 16.7064 2.08637 16.4216 2.30006L7.98828 7.66673H4.15495C3.64662 7.66673 3.1591 7.86866 2.79966 8.22811C2.44022 8.58755 2.23828 9.07506 2.23828 9.58339V13.4167C2.23828 13.9251 2.44022 14.4126 2.79966 14.772C3.1591 15.1315 3.64662 15.3334 4.15495 15.3334H7.98828L16.4216 20.7001C16.7064 20.9136 17.045 21.0437 17.3995 21.0756C17.754 21.1076 18.1104 21.0402 18.4288 20.881C18.7471 20.7219 19.0149 20.4772 19.202 20.1744C19.3892 19.8716 19.4883 19.5227 19.4883 19.1667V3.83339C19.4884 3.47739 19.3893 3.1284 19.2022 2.82552C19.0152 2.52265 18.7474 2.27787 18.429 2.11862V2.11862Z" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.98828 7.66669V15.3334" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M30.9837 8.14581L24.2754 14.8541" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M24.2754 8.14581L30.9837 14.8541" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export const CloseSVG = ({ fill = "gray", size = "26" }) => {
  return (
    <svg
      width={size}
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M18.7368 6.73462L6.73682 18.7346"
        stroke={fill}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.73682 6.73462L18.7368 18.7346"
        stroke={fill}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
