import React, { useState, useRef } from "react";
import Tooltip from "./Tooltip";
import useBoop from '../hooks/use-boop';
import {
  ChevronSVG,
  SignOutSVG,
  KeyboardSVG,
  SoundOnSVG,
  MuteSVG,
  ChevronDownSVG,
} from "../imgs/DynamicIcons";
import { tooltipConfig } from '../helpers/config';
import useSound from "use-sound";
import SoundOnSFX from "../sfx/whoosh.mp3";
import { API, graphqlOperation } from "aws-amplify";
import { updateSettings } from "../graphql/mutations";

const variants = {
  open: {
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.07,
    },
  },
};

const AppNav = ({
  offset,
  setOffset,
  signOut,
  openKeyboardModal,
  username,
  sfxOn,
  userSettings,
  setUserSettings,
  setSfxOn,
  resolution,
  setResolution,
}) => {

  const [playSoundOn] = useSound(SoundOnSFX, { volume: 0.1 });


  const tooltipCustom = { y: 12, x: 0 };
  const [keyboardTooltipConfig, keyboardTooltipTrigger] = useBoop({...tooltipConfig, ...tooltipCustom});
  const [leftTooltipConfig, leftTooltipTrigger] = useBoop({...tooltipConfig, ...tooltipCustom});
  const [rightTooltipConfig, rightTooltipTrigger] = useBoop({...tooltipConfig, ...tooltipCustom});
  const [todayTooltipConfig, todayTooltipTrigger] = useBoop({...tooltipConfig, ...tooltipCustom});
  const [signOutTooltipConfig, signOutTooltipTrigger] = useBoop({...tooltipConfig, ...tooltipCustom});

  const [leftClicked, setLeftClicked] = useState(false)
  const [rightClicked, setRightClicked] = useState(false)

  const [showResolutionSelectionMenu, setShowResolutionSelectionMenu] = useState(false)

  const containerRef = useRef(null);

  const handleToggleResolutionSelection = () => {
    setShowResolutionSelectionMenu(!showResolutionSelectionMenu);
  }

  const handleLeftScoot = () => {
    setLeftClicked(true)
    setOffset(offset - 1)
  }

  const handleRightScoot = () => {
    setRightClicked(true)
    setOffset(offset + 1)
  }

  const toggleSound = async () => {
    if (!sfxOn) playSoundOn();
    setSfxOn(!sfxOn);
    try {
      const settings = {
        ...userSettings,
        soundOn: !sfxOn,
      };

      delete settings.createdAt;
      delete settings.updatedAt;
      delete settings.owner;

      const { data } = await API.graphql(
        graphqlOperation(updateSettings, { input: settings })
      );

      const returnedUserSettings = data.updateSettings;
      setUserSettings(returnedUserSettings);
    } catch (error) {
      console.info("ERROR creating settings: ", error);
    }
  };

  return (
    <div id="app-nav">
      <div className="left-btns-holster">
        <span
          className="nav-btns btn-subtle tooltip-activator"
          onClick={openKeyboardModal}
          onMouseEnter={keyboardTooltipTrigger}
        >
          <KeyboardSVG size={21} />
          <Tooltip config={keyboardTooltipConfig} align="bottom-left wide">
            Keyboard shortcuts
          </Tooltip>
        </span>
        <span
          className="nav-btns btn-subtle tooltip-activator"
          onClick={toggleSound}
          onMouseEnter={keyboardTooltipTrigger}
        >
          {sfxOn ? <SoundOnSVG /> : <MuteSVG />}
          <Tooltip config={keyboardTooltipConfig} align="bottom-left">
            {sfxOn ? "Sound On" : "Sound Off"}
          </Tooltip>
        </span>
        <span className="relative flex nav-selector">
          <span
            onClick={handleToggleResolutionSelection}
            className="btn-subtle bordered tooltip-activator"
          >
            {resolution === "week" ? "Week" : "Year"}
            <ChevronDownSVG size={10} />
          </span>
          {showResolutionSelectionMenu && (
            <div
              ref={containerRef}
              className="popover resolution-selector"
              animate={showResolutionSelectionMenu ? "open" : "closed"}
              variants={variants}
            >
              <div
                className={`btn-subtle menu-item ${
                  resolution === "year" ? "selected" : ""
                }`}
                onClick={() => {
                  setResolution("year");
                  setShowResolutionSelectionMenu(false);
                }}
              >
                Year View
              </div>
              <div
                className={`btn-subtle menu-item ${
                  resolution === "week" ? "selected" : ""
                }`}
                onClick={() => {
                  setResolution("week");
                  setShowResolutionSelectionMenu(false);
                }}
              >
                Week View
              </div>
            </div>
          )}
        </span>
        <span
          className="nav-btns btn-subtle chevron tooltip-activator"
          onClick={handleLeftScoot}
          onMouseEnter={leftTooltipTrigger}
        >
          <ChevronSVG left animate={leftClicked} />
          <Tooltip config={leftTooltipConfig} align="bottom-left">
            Previous week
          </Tooltip>
        </span>
        <span
          className="nav-btns btn-subtle chevron tooltip-activator"
          onClick={handleRightScoot}
          onMouseEnter={rightTooltipTrigger}
        >
          <ChevronSVG animate={rightClicked} />
          <Tooltip config={rightTooltipConfig} align="bottom-left">
            Next week
          </Tooltip>
        </span>

        {offset !== 0 && (
          <span
            className="btn-subtle bordered reset tooltip-activator"
            onClick={() => setOffset(0)}
            onMouseEnter={todayTooltipTrigger}
          >
            Today
            <Tooltip config={todayTooltipConfig} align="bottom-left">
              Current {resolution}
            </Tooltip>
          </span>
        )}
      </div>

      <span
        id="sign-out"
        className="nav-btns btn-subtle tooltip-activator right"
        onClick={signOut}
        onMouseEnter={signOutTooltipTrigger}
      >
        <span className="username">{username}</span>
        <SignOutSVG size={16} />
        <Tooltip config={signOutTooltipConfig} align="bottom-right">
          Sign out
        </Tooltip>
      </span>

      {showResolutionSelectionMenu && (
        <span
          className="scrim"
          onClick={() => {
            setShowResolutionSelectionMenu(false);
          }}
        />
      )}
    </div>
  );
};

export default AppNav;
