import React from 'react'
import Logo from '../imgs/arc-logo-full.png'

const Landing = ({formState, setFormState}) => {

  return (
    <div className="App landing">
      <div className="navbar">
        <h1 className="hidden">Arc!</h1>
        <span
          className="btn-secondary right"
          onClick={() => {
            setFormState({ ...formState, formType: "signUp" });
          }}
        >
          Try preview
        </span>
        <span
          className="btn-naked right"
          style={{ marginRight: 8 }}
          onClick={() => {
            setFormState({ ...formState, formType: "signIn" });
          }}
        >
          Log in
        </span>
      </div>

      <div className="flexer">
        <img src={Logo} alt="Arc-logo" />
        <button
          className="btn-secondary jumbo"
          onClick={() => {
            setFormState({ ...formState, formType: "signUp" });
          }}
        >
          Try preview
        </button>

        <span className="bottom-text">
          Follow
          <a
            href="http://www.twitter.com/get_arc"
            target="_blank"
            rel="noopener noreferrer"
          >
            @get_arc
          </a>
          on Twitter
        </span>
      </div>
    </div>
  );
}

export default Landing