export const resortDay = (items, position, itemToInsert, removeItem) => {
    const firstPortion = itemToInsert
      ? [...items.slice(0, position), itemToInsert]
      : [...items.slice(0, position)]

    const middlePosition = removeItem ? position + 1 : position
    const InNeedOfDayOrderUpdated = items.slice(middlePosition)
    const IncrementDecremnt = removeItem ? -1 : 1;
    const secondPortion = InNeedOfDayOrderUpdated.map((item) => {
      return {...item, dayOrder: item.dayOrder + IncrementDecremnt}
    })

    const resortedDay = [...firstPortion, ...secondPortion]

    return {
      resortedDay,
      itemsNeedingDayOrderDBUpdate: secondPortion
    }
  }