import React from 'react'

const SkeletonItemsLoader = () => {
  return (
    <>
      <span className="skeleton-box" style={{width: "70%"}} />
      <span className="skeleton-box" style={{width: "80%"}} />
      <span className="skeleton-box" style={{width: "70%"}} />
      <span className="skeleton-box" style={{width: "60%"}} />
      <span className="skeleton-box" style={{width: "40%"}} />
      <span className="skeleton-box" style={{width: "50%"}} />
    </>
  )
}

export default SkeletonItemsLoader;