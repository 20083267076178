import React, { useState, useRef, useEffect } from "react";
import {
  TextSVG,
  AnimatedTrashSVG,
  DividerSVG,
  ClockSVG,
  CheckboxSVG,
  CalTodaySVG,
  SignificanceSVG,
} from "../imgs/DynamicIcons";
import TimePicker from 'react-time-picker';
import { motion } from 'framer-motion'

const ItemMenu = ({
  title,
  onOpenItemView,
  checkCheckbox,
  dayIdx,
  description,
  closeMenu,
  divideItem,
  removeItemRegular,
  time,
  updateTime,
  straightToTime,
  isComplete,
  playCheckingStaggered,
  sendToToday,
  significance,
  updateSignificance
}) => {

  const [pickingTime, setPickingTime] = useState(false)
  const [dangerHover, setDangerHover] = useState(false)

  const handleComplete = (e) => {
    checkCheckbox();
    playCheckingStaggered()
    closeMenu(e);
  }

  const containerRef = useRef(null)

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(true)
  }, [])

  const variants = {
    open: {
      transition: {
        delayChildren: .2,
        staggerChildren: .07
      }
    },
  }

  const childVariants = {
    closed: {
      y: -4,
    },
    open: {
      y: 0
    },
  }

  if (pickingTime || straightToTime) {
    return (
      <div className={`popover popover-time ${dayIdx === 6 ? "last" : ""}`}>
        <TimePicker
          autoFocus={true}
          className="clock"
          disableClock={true}
          hourPlaceholder="hh"
          minutePlaceholder="mm"
          onChange={(val) => updateTime(val)}
          clearIcon={null}
          value={time}
        />
        <span
          onClick={(e) => {
            updateTime('clear')
            setPickingTime(false)
            closeMenu(e)
          }} 
          className="btn-subtle btn-clear">clear</span>
      </div>
    )
  }

  return (
    <motion.div
      ref={containerRef}
      animate={isOpen ? "open" : "closed"}
      variants={variants}
      className={`popover item-menu-popover ${dayIdx === 6 ? "last" : ""}`}
    >
      {title && (
        <motion.span
          variants={childVariants}
          initial="closed"
          animate="open"
          whileTap={{ scale: 0.98 }}
          onClick={handleComplete}
          className="option"
        >
          <CheckboxSVG fill="#44536c" size={14} />
          <span className="icon-left">
            Mark {`${isComplete ? "Incomplete " : "Complete "}`}
          </span>
          <code>
            <span className="word symbol">⌘</span>
            <span>+</span>
            <span className="word">Return</span>
          </code>
        </motion.span>
      )}

      {title && (
        <motion.span
          variants={childVariants}
          initial="closed"
          animate="open"
          whileTap={{ scale: 0.98 }}
          onClick={onOpenItemView}
          className="option"
        >
          <TextSVG fill="#44536c" size={10} />
          <span className="icon-left" style={{ paddingLeft: 2 }}>
            {`${description ? "Edit " : "Add "}`}Details
          </span>
          <code>
            <span className="word symbol">⌘</span>
            <span>+</span>
            <span className="word">D</span>
          </code>
        </motion.span>
      )}

      <motion.span
        variants={childVariants}
        initial="closed"
        animate="open"
        className="option full-height"
        style={{ marginLeft: -3, padding: "12px 0" }}
      >
        <SignificanceSVG fill="#44536c" size={15} />
        <span className="icon-left">Significance</span>
        <span className="icon-left flex sig-wrap">
          <span
            className={`significance-btn equal-width ${
              significance === 1 || significance === null ? "active" : ""
            }`}
            onClick={() => updateSignificance(1)}
          >
            <div>1</div>
            <div className="shortcut">
              ⌘<span>+</span>
              <span className="word">1</span>
            </div>
          </span>
          <span
            className={`significance-btn equal-width ${
              significance === 2 ? "active" : ""
            }`}
            onClick={() => updateSignificance(2)}
          >
            <div>2</div>
            <div className="shortcut">
              ⌘<span>+</span>
              <span className="word">2</span>
            </div>
          </span>
          <span
            className={`significance-btn equal-width ${
              significance === 3 ? "active" : ""
            }`}
            onClick={() => updateSignificance(3)}
          >
            <div>3</div>
            <div className="shortcut">
              ⌘<span>+</span>
              <span className="word">3</span>
            </div>
          </span>
        </span>
      </motion.span>

      <motion.span
        variants={childVariants}
        initial="closed"
        animate="open"
        whileTap={{ scale: 0.98 }}
        onClick={sendToToday}
        className="option"
      >
        <CalTodaySVG fill="#44536c" size={15} />
        <span className="icon-left">Send to Today</span>
      </motion.span>

      <motion.span
        variants={childVariants}
        initial="closed"
        animate="open"
        whileTap={{ scale: 0.98 }}
        onClick={() => {
          setPickingTime(true);
        }}
        className="option"
      >
        <ClockSVG fill="#44536c" size={18} />
        <span className="icon-left">{`${time ? "Change " : "Add "}`} Time</span>
        <code>
          <span className="word">"@10am"</span>
        </code>
      </motion.span>

      <motion.span
        variants={childVariants}
        initial="closed"
        animate="open"
        whileTap={{ scale: 0.98 }}
        onClick={(e) => {
          divideItem(false);
          closeMenu(e);
        }}
        className="option"
      >
        <DividerSVG fill="#44536c" size={13} />
        <span className="icon-left">Insert Divider</span>
        <code>
          <span className="word">"---"</span>
        </code>
      </motion.span>

      <motion.span
        variants={childVariants}
        initial="closed"
        animate="open"
        whileTap={{ scale: 0.98 }}
        onClick={removeItemRegular}
        className="option danger"
        onMouseEnter={() => setDangerHover(true)}
        onMouseLeave={() => setDangerHover(false)}
      >
        <AnimatedTrashSVG
          fill={dangerHover ? "#dd3f5b" : "#44536c"}
          size={13}
          hovering={dangerHover}
        />
        <span
          className="icon-left"
          style={{ color: dangerHover ? "#dd3f5b" : "#172b4d" }}
        >
          Delete Item
        </span>
        <code>
          <span
            className="word symbol"
            style={{ color: dangerHover && "rgba(221, 63, 91, .5)" }}
          >
            ⌘
          </span>
          <span style={{ color: dangerHover && "rgba(221, 63, 91, .5)" }}>
            +
          </span>
          <span
            className="word"
            style={{ color: dangerHover && "rgba(221, 63, 91, .5)" }}
          >
            Del
          </span>
        </code>
      </motion.span>
    </motion.div>
  );
}

export default ItemMenu;