import React, { useState, useEffect } from "react";
import { itemsByCreatorByDate } from '../graphql/queries'
import { createItem, updateItem, deleteItem, updateSettings } from '../graphql/mutations'
import { API, graphqlOperation } from 'aws-amplify';

import moment from 'moment'
import { DragDropContext, Droppable} from 'react-beautiful-dnd'
import useSound from 'use-sound';

import Item from './Item'
import SkeletonItemsLoader from './SkeletonItemsLoader'

import { resortDay } from '../helpers/resortDay'
import { isCurrentDay, isPastDay } from "../helpers/dating";
import { hydrationDays } from "../helpers/data";

import BlipSFX from '../sfx/blip2.mp3';
import DragEndSFX from '../sfx/drop.mp3';
import DragEndNoDestSFX from '../sfx/drop-homeless.mp3';
import RemoveSFX from '../sfx/nope.mp3';


function WeekView({
  user,
  sfxOn,
  userSettings,
  setUserSettings,
  weeksOffset,
  setWeeksOffset,
}) {
  const [focused, setFocus] = useState({
    col: 0,
    item: 3,
    stamp: null,
  });

  const [dayChecked, setDayChecked] = useState(false);

  const checkDayOnFocus = async () => {
    if (!user || dayChecked || !userSettings) return;
    setDayChecked(true);
    const startOfDay = moment().startOf("day");

    // TODO: figure out why lastChecked was no longer used anywhere

    // let lastChecked = userSettings.lastDayChecked
    //   ? userSettings.lastDayChecked
    //   : startOfDay;

    // For testing, swap lines below
    // lastChecked = moment(lastChecked).subtract(1, 'days')

    // lastChecked = moment(lastChecked);

    // update last day checked on user settings
    try {
      const settings = {
        ...userSettings,
        soundOn: sfxOn,
        lastDayChecked: startOfDay.format("YYYY-MM-DD"),
      };
      delete settings.createdAt;
      delete settings.updatedAt;
      delete settings.owner;

      const { data } = await API.graphql(
        graphqlOperation(updateSettings, { input: settings })
      );
      const returnedUserSettings = data.updateSettings;
      setUserSettings(returnedUserSettings);
      setDayChecked(true);
    } catch (error) {
      console.info("ERROR updating settings: ", error);
    }
  };

  useEffect(() => {
    if (userSettings && !dayChecked) checkDayOnFocus();
    // eslint-disable-next-line
  }, [userSettings]);

  const updateDisplayedDates = () => {
    if (arrayedDays.length === 0) return;
    const newArrayedDays = [...arrayedDays];
    const momentNow = moment().startOf("week").add(weeksOffset, "weeks");
    newArrayedDays[0].date = momentNow.format("YYYY-MM-DD");
    newArrayedDays[1].date = moment(momentNow)
      .add(1, "days")
      .format("YYYY-MM-DD");
    newArrayedDays[2].date = moment(momentNow)
      .add(2, "days")
      .format("YYYY-MM-DD");
    newArrayedDays[3].date = moment(momentNow)
      .add(3, "days")
      .format("YYYY-MM-DD");
    newArrayedDays[4].date = moment(momentNow)
      .add(4, "days")
      .format("YYYY-MM-DD");
    newArrayedDays[5].date = moment(momentNow)
      .add(5, "days")
      .format("YYYY-MM-DD");
    newArrayedDays[6].date = moment(momentNow)
      .add(6, "days")
      .format("YYYY-MM-DD");
    newArrayedDays[0].items = [];
    newArrayedDays[1].items = [];
    newArrayedDays[2].items = [];
    newArrayedDays[3].items = [];
    newArrayedDays[4].items = [];
    newArrayedDays[5].items = [];
    newArrayedDays[6].items = [];
    setArrayedDays(newArrayedDays);
  };

  const [weekInView, setWeekInView] = useState([null, null]);

  useEffect(() => {
    updateDisplayedDates();
    const momentNow = moment().startOf("week");
    const startOfWeek = momentNow
      .add(weeksOffset, "weeks")
      .format("YYYY-MM-DD");
    const endOfWeek = moment(startOfWeek).add(6, "days").format("YYYY-MM-DD");
    setWeekInView([startOfWeek, endOfWeek]);

    // If we've fetched them already, use the the in memory cache
    // const cachedWeek = cachedWeeks[weeksOffset.toString()]
    // if (Array.isArray(cachedWeek)) {
    // if (cachedWeeks.includes(weeksOffset)) { // this one didn't work cos new items or updates change
    // arrayedDays, not items
    if (false) {
      // setArrayedDays(cachedWeek)
      // setDaysArrayTrigger((prev) => prev + 1)
    } else {
      fetchWeek(startOfWeek, endOfWeek).then(() => {
        if (userSettings && !userSettings.dayChecked) checkDayOnFocus();
      });
    }
    // eslint-disable-next-line
  }, [weeksOffset]);

  const [items, setItems] = useState([]);
  const [arrayedDays, setArrayedDays] = useState([]);
  // const [cachedWeeks, setCachedWeeks] = useState([]);
  // const [daysArrayTrigger, setDaysArrayTrigger] = useState(0);

  // See Appendix 1
  // Fires once on app load
  useEffect(() => {
    // if there are arrayed days in state, use those, if not, scaffold them out
    const days = arrayedDays.length > 0 ? [...arrayedDays] : [...hydrationDays];
    // const days = [...arrayedDays]

    items.forEach((item) => {
      days.forEach((day, dayIdx) => {
        // push in everything that matches this day
        if (item.date === day.date) {
          // Don't push if arrayedDays[dayIdx].items already contains an item
          // with its id === to the item.id.
          let match = false;
          days[dayIdx].items.forEach((i) => {
            if (i.id === item.id) {
              match = true;
            }
          });

          if (match) return; // Todo: we want to replace it with the one from items cos its newer?
          day.items.push(item);
        }
        // TODO: More perf if we can push in order instead of hammering this sort() after the fact
        day.items.sort((a, b) => a.dayOrder - b.dayOrder);
      });
    });

    // const key = weeksOffset.toString()

    // const newCachedWeeks = {...cachedWeeks}

    // newCachedWeeks[key] = days;

    // console.log({newCachedWeeks});

    // setCachedWeeks(newCachedWeeks)

    setArrayedDays(days);
    if (isLoading) setIsLoading(false);
    // eslint-disable-next-line
  }, [items]); // Fires once on app load

  const [isLoading, setIsLoading] = useState(false);

  const fetchWeek = async (startDate, endDate) => {
    if (!startDate || !user) return;
    setIsLoading(true);
    try {
      const itemData = await API.graphql(
        graphqlOperation(itemsByCreatorByDate, {
          creator: user.username,
          date: {
            between: [startDate, endDate],
          },
        })
      );

      const itemList = itemData.data.itemsByCreatorByDate.items;

      // const allItems = [
      //   ...items,
      //   ...itemList
      // ]

      setItems(itemList);

      // const newWeeksCached = [
      //   ...cachedWeeks,
      //   weeksOffset
      // ]

      // setCachedWeeks(newWeeksCached)
    } catch (error) {
      console.info("ERROR fetching: ", error);
    }
  };

  // TODO: figure out how to clear the items appropriately on user change
  useEffect(() => {
    if (user) {
      setArrayedDays([]);
      if (!weekInView[0]) {
        return;
      }
      fetchWeek(weekInView[0], weekInView[1]).then(() => {
        if (userSettings && !userSettings.dayChecked) checkDayOnFocus();
      });
    } else {
      console.log("clearing user data");
      setArrayedDays([]);
      setItems([]);
    }
    // eslint-disable-next-line
  }, [user]);

  const handleCreateItem = async (dayIdx, position, isDivider = false) => {
    try {
      const tempItem = {
        id: "temp-until-comes-back-from-db-" + new Date().toISOString(),
        title: "",
        completed: false,
        creator: user.username,
        date: arrayedDays[dayIdx].date,
        description: "",
        dayOrder:
          position || position === 0 ? position : arrayedDays[dayIdx].length,
        isDivider: isDivider ? true : false,
        significance: 1,
      };

      // First insert into UI with false ID
      // Resort the day
      const { resortedDay, itemsNeedingDayOrderDBUpdate } = resortDay(
        arrayedDays[dayIdx].items,
        position,
        tempItem
      );

      // Update the UI with the new temp item
      const newArrayedDays = [...arrayedDays];
      newArrayedDays[dayIdx].items = resortedDay;
      setArrayedDays(newArrayedDays);

      // Remove the faux id (will get created by the graphql call)
      const item = { ...tempItem, id: undefined };

      const { data } = await API.graphql(
        graphqlOperation(createItem, { input: item })
      );
      const freshItem = data.createItem;

      // Update the UI again with the real new item's ID
      const secondNewArrayedDays = [...arrayedDays];
      secondNewArrayedDays[dayIdx].items[freshItem.dayOrder] = freshItem;
      setArrayedDays(secondNewArrayedDays);

      // Update the necessary items in the database
      saveItemSetToDB(itemsNeedingDayOrderDBUpdate);
    } catch (error) {
      console.info("ERROR creating item: ", error);
    }
  };

  const handleUpdateItem = async (
    currentItem,
    index,
    dayIdx,
    title,
    description,
    checked,
    time = null,
    newPosition = false,
    convertToDivider = false,
    missedCount = null,
    newDate = null,
    newSig = false
  ) => {
    const item = {
      ...currentItem,
      title: title,
      description: description,
      completed: checked,
      time: time,
      date: newDate ? newDate : currentItem.date,
      dayOrder: newPosition ? newPosition : index,
      isDivider: convertToDivider ? true : false,
      missedCount: missedCount ? missedCount : currentItem.missedCount,
      significance: newSig ? newSig : currentItem.significance,
    };

    // update app state
    const newArrayedDays = [...arrayedDays];

    // if we're sending to today
    if (newDate) {
      // remove the item from current day and and resort that day
      const { resortedDay } = resortDay(
        arrayedDays[dayIdx].items,
        index,
        false, // hasNewItem
        true // removeItem
      );
      newArrayedDays[dayIdx].items = resortedDay;

      // Add removed item to current day
      arrayedDays.forEach((day, dIdx) => {
        // If current day is within the view
        if (day.date === newDate) {
          item.dayOrder = newArrayedDays[dIdx].items.length;
          newArrayedDays[dIdx].items.push(item);
        }
      });
    } else {
      newArrayedDays[dayIdx].items[index] = item;
    }

    setArrayedDays(newArrayedDays);

    try {
      delete item.createdAt;
      delete item.updatedAt;
      delete item.owner;

      API.graphql(graphqlOperation(updateItem, { input: item }));
    } catch (error) {
      console.info("ERROR updating item: ", error.errors[0].message);
    }
  };

  const handleDeleteItem = async (id, position, dayIdx, cancelSound) => {
    if (!cancelSound && sfxOn) playRemove();
    try {
      // Remove item and update relevant item.dayOrder-s
      const { resortedDay, itemsNeedingDayOrderDBUpdate } = resortDay(
        arrayedDays[dayIdx].items,
        position,
        false, // hasNewItem
        true // removeItem
      );

      // Update UI state
      const newArrayedDays = [...arrayedDays];
      newArrayedDays[dayIdx].items = resortedDay;
      setArrayedDays(newArrayedDays);

      const payload = { id };
      API.graphql(graphqlOperation(deleteItem, { input: payload }));

      // Update the necessary items dayOrder-s in the database
      saveItemSetToDB(itemsNeedingDayOrderDBUpdate);
    } catch (error) {
      console.info("ERROR deleting: ", error);
    }
  };

  const saveItemSetToDB = (updatees) => {
    updatees.forEach(async (i) => {
      try {
        const item = {
          id: i.id,
          title: i.title,
          description: i.description,
          completed: i.completed,
          time: i.time,
          dayOrder: i.dayOrder,
          date: i.date,
          missedCount: i.missedCount,
        };
        API.graphql(graphqlOperation(updateItem, { input: item }));
      } catch (error) {
        console.info("ERROR updating set (saveItemsToDB)");
      }
    });
  };

  const handleInsertDivider = (
    dayIdx,
    index,
    currentItem,
    convertTheItem = false
  ) => {
    if (convertTheItem) {
      // user typed '---' or '——'
      handleUpdateItem(
        currentItem,
        index,
        dayIdx,
        currentItem.title,
        currentItem.description,
        currentItem.completed,
        false, // newTime
        false, // newPosition
        true // convertToDivider
      );
    } else {
      // user inserted divider via menu
      handleCreateItem(
        dayIdx,
        index + 1,
        true // isDivider
      );
    }
  };

  const nudgeItem = (dayIdx, itemIndex, direction) => {
    const newArrayedDays = [...arrayedDays];
    const dayItems = newArrayedDays[dayIdx].items;
    const oldIndex = itemIndex;
    const newIndex = oldIndex + direction;

    if (newIndex >= dayItems.length || newIndex < 0) return;

    playPitchedSpotSwitchBlipSFX(itemIndex);

    const movingItem = dayItems[itemIndex];
    const nudgedItem = dayItems[itemIndex + direction];

    movingItem.dayOrder = movingItem.dayOrder + direction;
    nudgedItem.dayOrder = oldIndex;

    dayItems.splice(newIndex, 0, dayItems.splice(oldIndex, 1)[0]);

    // Update UI
    newArrayedDays[dayIdx].items = dayItems;
    setArrayedDays(newArrayedDays);

    // Update database
    saveItemSetToDB([movingItem, nudgedItem]);
  };

  const [playRemove] = useSound(RemoveSFX, { volume: 0.08 });
  const [playDragEnd] = useSound(DragEndSFX, { volume: 0.14 });
  const [playDragEndNoDest] = useSound(DragEndNoDestSFX, { volume: 0.8 });

  const onDragEnd = (result) => {
    setIsDragon(false);
    const { source, destination } = result;

    if (!destination) {
      if (sfxOn) playDragEndNoDest();
      return;
    }

    if (sfxOn) playDragEnd();

    const sourceIdx = arrayedDays.findIndex(
      (d) => d.date === source.droppableId
    );
    const sourceDay = arrayedDays[sourceIdx];
    const sourceItems = [...sourceDay.items];
    const newArrayedDays = [...arrayedDays];

    // If dropped into a different column...
    if (source.droppableId !== destination.droppableId) {
      const destIdx = arrayedDays.findIndex(
        (d) => d.date === destination.droppableId
      );
      const destDay = arrayedDays[destIdx];
      const destItems = [...destDay.items];

      // Process item to move
      const movedItem = sourceItems[source.index];
      movedItem.dayOrder = destination.index;
      movedItem.date = destination.droppableId;

      // mark as missed if necessary
      const startDay = moment().startOf("week");
      const destDateDay = startDay.add(destIdx, "days");

      if (destDateDay < moment().startOf("day") && !movedItem.completed) {
        movedItem.missedCount = movedItem.missedCount
          ? movedItem.missedCount
          : 1;
      } else {
        if (movedItem.missedCount) movedItem.missedCount = 0;
      }

      // Insert item and update relevant item.dayOrder-s
      const { resortedDay } = resortDay(
        destItems,
        destination.index,
        movedItem
      );
      const newDestItems = resortedDay;

      // Remove item from source day and update relevant dayOrders
      const results = resortDay(
        sourceItems,
        source.index,
        false, // hasNewItem
        true // removeItem
      );
      const newSourceItems = results.resortedDay;

      // Update UI (arrayedDays)
      newArrayedDays[sourceIdx].items = newSourceItems;
      newArrayedDays[destIdx].items = newDestItems;
      setArrayedDays(newArrayedDays);

      // For now, just update the entire day until we have some more time to update
      // just the portion we need... results.itemsNeedingDayOrderDBUpdate needs to be made smarter
      saveItemSetToDB([...newDestItems, ...newSourceItems]);
    } else {
      // If dropped into the same column at different order
      const moveditem = sourceItems[source.index];
      moveditem.dayOrder = destination.index;

      // First remove the item and resort day without it
      const { resortedDay } = resortDay(
        sourceItems,
        source.index,
        false, // hasNewItem
        true // removeItem
      );

      // Add removed item and resort day with it
      const results = resortDay(resortedDay, destination.index, moveditem);

      // Update UI (arrayedDays)
      newArrayedDays[sourceIdx].items = results.resortedDay;
      setArrayedDays(newArrayedDays);

      // For now, just update the entire day until we have some more time to update
      // just the portion we need... results.itemsNeedingDayOrderDBUpdate needs to be made smarter
      saveItemSetToDB([...results.resortedDay]);
    }
  };

  const handleWindowFocus = () => {
    if (userSettings && !dayChecked) {
      checkDayOnFocus();
    }
  };

  useEffect(() => {
    window.addEventListener("focus", handleWindowFocus);
    return () => window.removeEventListener("focus", handleWindowFocus);
    // eslint-disable-next-line
  }, []);

  // 🐲
  const [isDragon, setIsDragon] = useState(false);

  const firstDragBlipPitch = 1;
  const [blipRecentDestination, setBlipRecentDestination] = useState(false);
  const [dragBlipPitch, setDragBlipPitch] = useState();
  const [playDragBlip] = useSound(BlipSFX, {
    playbackRate: dragBlipPitch,
    volume: 0.5,
  });

  const handleDragBlip = (e) => {
    if (e.destination === null) return;
    // If we're dragging to a diff column we don't want a sound unless it was just in there
    if (e.destination.droppableId !== e.source.droppableId) {
      if (blipRecentDestination !== e.destination.droppableId) {
        setBlipRecentDestination(e.destination.droppableId);
        return;
      }
    }

    playPitchedSpotSwitchBlipSFX(e.destination.index);
  };

  const playPitchedSpotSwitchBlipSFX = (index) => {
    setDragBlipPitch(firstDragBlipPitch + index * 0.07);
    if (sfxOn) playDragBlip();
  };

  const startDay = moment(weekInView[0]).format("MMM D");
  let endDay = moment(weekInView[1]).format("MMM D, YYYY");

  // If within same month, don't repeat month text
  if (startDay.substring(0, 3) === endDay.substring(0, 3)) {
    endDay = endDay.slice(4);
  }

  /*                               The Actual App!                                */

  return (
    <div>
      <div className="row date-display">
        <h2 className="page-header">{`${startDay} – ${endDay}`}</h2>
      </div>

      <div className="row">
        <DragDropContext
          onDragStart={() => setIsDragon(true)}
          onDragUpdate={handleDragBlip}
          onDragEnd={(result) => onDragEnd(result)}
        >
          {arrayedDays.map((day, dayIdx) => {
            const date = day.date;
            const isToday = weeksOffset !== 0 ? false : isCurrentDay(dayIdx);
            const isBeforeCurrentDay =
              weeksOffset === 0
                ? isPastDay(dayIdx)
                : weeksOffset < 0
                ? true
                : false;

            if (isLoading) {
              return (
                <div className="col-wrapper" key={`${date}-daywrap`}>
                  <h2 className="col-header">
                    <span className="dow-text" style={{ color: "#172b4d" }}>
                      {day.name}
                    </span>
                  </h2>
                  <SkeletonItemsLoader />
                </div>
              );
            }

            return (
              <div
                className={`col-wrapper ${isToday ? "col-today" : ""}`}
                key={`${date}-daywrap`}
              >
                <h2 className={`col-header ${isToday ? "today-title" : ""}`}>
                  {isToday && (
                    <div
                      style={{ display: "none" }}
                      className="current-day tooltip"
                    />
                  )}
                  <span
                    className="dow-text"
                    style={{
                      color: isBeforeCurrentDay ? "#B7BAC1" : " #172b4d", // $gray60 / $text
                    }}
                  >
                    {day.name}
                  </span>
                  <span className="date" data-current={isToday}>
                    {moment(date).format("MMM D")}
                  </span>
                </h2>
                <Droppable droppableId={date} key={date}>
                  {(provided, snapshot) => {
                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          backgroundColor: snapshot.isDraggingOver
                            ? "#edf1ff"
                            : "transparent",
                        }}
                        className="col-list"
                      >
                        {day.items.map((item, itemIdx) => {
                          let isFocused = false;
                          if (focused.col === dayIdx) {
                            if (focused.item === itemIdx) {
                              isFocused = true;
                            } else if (
                              itemIdx === day.items.length - 1 &&
                              focused.item > day.items.length - 1
                            ) {
                              isFocused = true;
                            }
                          }
                          return (
                            <Item
                              currentItem={item}
                              index={itemIdx}
                              key={item.id}
                              dayItems={day.items}
                              dayIdx={dayIdx}
                              handleCreateItem={handleCreateItem}
                              handleUpdateItem={handleUpdateItem}
                              handleDeleteItem={handleDeleteItem}
                              handleInsertDivider={handleInsertDivider}
                              nudgeItem={nudgeItem}
                              setFocus={setFocus}
                              isLastItem={itemIdx === day.items.length - 1}
                              focus={isFocused}
                              isDragonSomeone={isDragon}
                              isBeforeCurrentDay={isBeforeCurrentDay}
                              contrastDuringDragover={snapshot.isDraggingOver}
                              sfxOn={sfxOn}
                              weeksOffset={weeksOffset}
                              setWeeksOffset={setWeeksOffset}
                            />
                          );
                        })}
                        {provided.placeholder}
                        <div
                          className="new-hot-zone"
                          onClick={() =>
                            handleCreateItem(dayIdx, day.items.length)
                          }
                          style={{
                            display: !snapshot.isDraggingOver ? "flex" : "none",
                          }}
                        >
                          <button
                            className="btn-item-new"
                            style={{
                              display:
                                day.items.length === 0 ? "block" : "none",
                            }}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    );
                  }}
                </Droppable>
              </div>
            );
          })}
        </DragDropContext>
      </div>
    </div>
  );
}

export default WeekView;