import React, {useEffect, useState} from 'react'

const Puffs = () => {
  const [active, setActive] = useState(false)

  useEffect(() => {
    setActive(true)
  }, [])

  return (
    <div className={`puffery ${active ? "scootydoo" : ""}`}>
      <div className="puff puff-1" />
      <div className="puff puff-2"/>
      <div className="puff puff-3"/>
      <div className="puff puff-4"/>
      <div className="puff puff-5"/>
    </div>
  )
}

export default Puffs;