/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getItem = /* GraphQL */ `
  query GetItem($id: ID!) {
    getItem(id: $id) {
      id
      title
      completed
      date
      creator
      dayOrder
      time
      description
      firstMissedId
      missedCount
      isDivider
      significance
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listItems = /* GraphQL */ `
  query ListItems(
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        completed
        date
        creator
        dayOrder
        time
        description
        firstMissedId
        missedCount
        isDivider
        significance
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getSettings = /* GraphQL */ `
  query GetSettings($id: ID!) {
    getSettings(id: $id) {
      id
      soundOn
      lastDayChecked
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listSettingss = /* GraphQL */ `
  query ListSettingss(
    $filter: ModelSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSettingss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        soundOn
        lastDayChecked
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const itemsByCreatorByDate = /* GraphQL */ `
  query ItemsByCreatorByDate(
    $creator: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByCreatorByDate(
      creator: $creator
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        completed
        date
        creator
        dayOrder
        time
        description
        firstMissedId
        missedCount
        isDivider
        significance
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
