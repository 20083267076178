import React, { useState, useRef, useEffect, useCallback } from "react"
import { Draggable } from 'react-beautiful-dnd'
import moment from 'moment'
import useSound from 'use-sound';
import PopDownSFX from '../sfx/pop-down.mp3';
import PopUpOffSFX from '../sfx/pop-up-off.mp3';
import PopUpOnSFX from '../sfx/pop-up-on.mp3';
import ItemView from "./ItemView"
import ItemMenu from "./ItemMenu"
import Tooltip from "./Tooltip"
import Divider from "./Divider"
import Puffs from "./Puffs"
import { GripperSVG, TextSVG, EllipsisSVG } from "../imgs/DynamicIcons";
import CheckBox from "react-animated-checkbox"
import TextareaAutosize from "react-textarea-autosize";
import {convertTime12to24, convertTime24to12} from "../helpers/dating"
import useBoop from '../hooks/use-boop';
import { tooltipConfig, deleteDuration } from '../helpers/config';

const Item = ({
  currentItem,
  index,
  dayItems,
  dayIdx,
  handleCreateItem,
  handleUpdateItem,
  handleDeleteItem,
  nudgeItem,
  focus,
  setFocus,
  isDragonSomeone,
  handleInsertDivider,
  isBeforeCurrentDay,
  contrastDuringDragover,
  sfxOn,
  weeksOffset,
  setWeeksOffset,
}) => {
  const divideItem = useCallback(
    (convertTheItem) => {
      handleInsertDivider(dayIdx, index, currentItem, convertTheItem);
      // if inserting item from last item, create a new item
      if (dayItems.length - 1 === index) {
        handleCreateItem(dayIdx, index + 1);
      } else {
        const jump = dayItems[index + 1].isDivider ? 2 : 1;
        setFocus({ col: dayIdx, item: index + jump });
      }
    },
    [
      dayIdx,
      dayItems,
      handleCreateItem,
      index,
      handleInsertDivider,
      setFocus,
      currentItem,
    ]
  );

  const parseRawText = useCallback(
    (raw) => {
      // Check if we should turn item into divider
      if (raw === "---" || raw === "——") {
        divideItem(true);
        return;
      }

      // If starts with a time, pull it out
      const startsWithTimeRegex =
        /^(\d{1,2}([:.]?\d{1,2})?([ ]?[a|p]m))|^noon$/gi;
      const perfectTimeMatch = raw.match(startsWithTimeRegex);
      if (perfectTimeMatch) {
        setSpecificTime(perfectTimeMatch[0]);
        return "";
      }

      const hasAtCharacter = raw.indexOf("@");
      let atTimeMatchedAndRemoved = false;
      if (hasAtCharacter !== -1) {
        // contains the "@" character
        const containsTimeRegex =
          /(\d{1,2}([:.]?\d{1,2})?([ ]?[a|p]m))|noon$/gi;
        const time = raw.match(containsTimeRegex);
        if (time) {
          const timeWithAtRegex =
            /@(\d{1,2}([:.]?\d{1,2})?([ ]?[a|p]m))|@(noon)$/gi;
          atTimeMatchedAndRemoved = raw.replace(timeWithAtRegex, "");
          atTimeMatchedAndRemoved.trim();
          /*
          For some reason everything except titles beginning with "@time" were 
          removing the time. This is hacky but solves that bug */
          const blurg = raw + " ";
          atTimeMatchedAndRemoved = atTimeMatchedAndRemoved
            ? atTimeMatchedAndRemoved
            : blurg.replace(timeWithAtRegex, "");

          setSpecificTime(time[0], atTimeMatchedAndRemoved.trim());
        }
      }

      return atTimeMatchedAndRemoved ? atTimeMatchedAndRemoved.trim() : raw;
    },
    // eslint-disable-next-line
    [divideItem]
  ); // end of parseRawText()

  const [displayTime, setDisplayTime] = useState(null);

  useEffect(() => {
    if (currentItem.time) setDisplayTime(convertTime24to12(currentItem.time));
  }, [currentItem.time]);

  const setSpecificTime = (time, titlePiece = false) => {
    const noonCheckedTime = time.toLowerCase() === "noon" ? "12pm" : time;
    const timeStrippedSpace = noonCheckedTime.split(" ").join("");
    const parsedTime = convertTime12to24(timeStrippedSpace);
    const titleToSend = titlePiece ? titlePiece : title;

    handleUpdateItem(
      currentItem,
      index,
      dayIdx,
      titleToSend,
      description,
      checked,
      parsedTime
    );
  };

  const updateTime = (newTime) => {
    let updatedTime = newTime;
    if (newTime === "clear") {
      setDisplayTime(undefined);
      updatedTime = undefined;
    }

    handleUpdateItem(
      currentItem,
      index,
      dayIdx,
      title,
      description,
      checked,
      updatedTime
    );
  };

  const updateSignificance = (newSig) => {
    handleUpdateItem(
      currentItem,
      index,
      dayIdx,
      title,
      description,
      checked,
      null, // new time
      false, // new position
      false, // convertToDivider
      null, // missedCount
      null, // newDate
      newSig,
    );
  };

  const sendToToday = () => {
    const currentDay = moment().format("YYYY-MM-DD");
    handleUpdateItem(
      currentItem,
      index,
      dayIdx,
      title,
      description,
      checked,
      null, // updatedTime
      false, // newPosition
      false, // convertToDivider
      null, // missedCount
      currentDay // newDate
    );
  };

  const titleRef = useRef();

  const [title, setTitle] = useState("");

  useEffect(() => {
    setTitle(parseRawText(currentItem.title));
  }, [currentItem.title, parseRawText]);

  /* 
  To mark as removed so we don't doublely remove an index when
  the focus is changed (which calls saveToDB; also contains
  a removeItem if length 0 condition)
*/
  const [wasRemoved, setWasRemoved] = useState(false);

  const saveThenReload = () => {
    if (title.length > 0) {
      handleUpdateItem(
        currentItem,
        index,
        dayIdx,
        title,
        description,
        checked,
        currentItem.time
      ).then(() => window.location.reload());
    } else {
      const cancelSound = true;
      handleDeleteItem(currentItem.id, index, dayIdx, cancelSound).then(() =>
        window.location.reload()
      );
    }
  };

  // called on title input blur, gripper mousedown, open menu, itemview's save/close
  const saveItemToDB = () => {
    if (wasRemoved || showMenu) {
      return;
    } else if (title.length > 0) {
      // const missedCount = isBeforeCurrentDay && !currentItem.missedCount
      //   ? 1
      //   : currentItem.missedCount;
      if (
        title !== currentItem.title ||
        description !== currentItem.description
      ) {
        handleUpdateItem(
          currentItem,
          index,
          dayIdx,
          title,
          description,
          checked,
          currentItem.time
        );
      }
    } else if (!wasRemoved) {
      // remove any items that have empty titles
      const cancelSound = true;
      handleDeleteItem(currentItem.id, index, dayIdx, cancelSound);
    }
  };

  const [description, setDescription] = useState("");
  useEffect(() => {
    setDescription(currentItem.description);
  }, [currentItem.description]);

  const handleChangeDescription = (newDescription) => {
    setDescription(newDescription);
  };

  const handleChangeTitle = (fromItemView) => {
    const titleValue =
      typeof fromItemView === "string" ? fromItemView : titleRef.current.value;
    setTitle(parseRawText(titleValue));
  };

  useEffect(() => {
    if (title.length === 0 && isDragonSomeone && !currentItem.isDivider) {
      saveItemToDB(); // to remove it
    }
    // eslint-disable-next-line
  }, [isDragonSomeone]);

  useEffect(() => {
    if (focus && titleRef && titleRef.current) {
      titleRef.current.focus();
    }
  }, [focus]);

  const hasDividerAbove = () => {
    const prevItem = dayItems[index - 1];
    return prevItem && prevItem.isDivider;
  };

  const checkKeyDown = (e) => {
    console.log(e.key);
    switch (e.key) {
      case "1":
        if (e.metaKey) {
          e.preventDefault();
          updateSignificance(1);
        }
        break;
      case "2":
        if (e.metaKey) {
          e.preventDefault();
          updateSignificance(2);
        }
        break;
      case "3":
        if (e.metaKey) {
          e.preventDefault();
          updateSignificance(3);
        }
        break;
      case "Enter":
        if (e.metaKey) {
          e.preventDefault();
          if (e.shiftKey) {
            setShowItemView(true);
          } else if (titleRef.current.value.length > 0) {
            if (sfxOn) playCheckingStaggered();
            checkCheckbox();
          }
        } else if (!e.shiftKey) {
          e.preventDefault();
          const position =
            titleRef.current.selectionStart === 0 ? index : index + 1;
          // For some reason this was all firing twice with position undefined
          // and I couldn't figure out why. This check prevents that from happening
          if (position || position === 0) {
            handleCreateItem(dayIdx, position);
          }
        }
        break;
      case "Backspace":
        e.stopPropagation();
        if (showMenu) return;
        if (titleRef?.current?.value?.length === 0 || currentItem.isDivider) {
          setWasRemoved(true);
          setFocus({
            col: dayIdx,
            item: index === 0 ? index : index - 1,
            stamp: new Date().getMilliseconds(),
          });
          const cancelSound = true;
          handleDeleteItem(currentItem.id, index, dayIdx, cancelSound);
        } else if (e.metaKey) {
          e.preventDefault();
          removeItemRegular();
        }
        break;
      case "ArrowUp":
        if (e.metaKey) {
          if (e.shiftKey) {
            setFocus({ col: dayIdx, item: 0 });
            break;
          }
          const jump = hasDividerAbove() ? 2 : 1;
          setFocus({ col: dayIdx, item: index - jump });
        } else if (e.altKey) {
          e.preventDefault();
          saveItemToDB();
          nudgeItem(dayIdx, index, -1);
          setFocus({ col: dayIdx, item: index - 1 });
        }
        break;
      case "ArrowDown":
        if (e.metaKey) {
          if (e.shiftKey) {
            setFocus({ col: dayIdx, item: dayItems.length });
            break;
          }
          const nextItem = dayItems[index + 1];
          const jump = nextItem && nextItem.isDivider ? 2 : 1;
          setFocus({ col: dayIdx, item: index + jump });
        } else if (e.altKey) {
          e.preventDefault();
          saveItemToDB();
          nudgeItem(dayIdx, index, 1);
          setFocus({ col: dayIdx, item: index + 1 });
        }
        break;
      case "ArrowLeft":
        if (e.metaKey && e.shiftKey) {
          e.preventDefault();
          // Nav to prev week
          setWeeksOffset(weeksOffset - 1);
        }
        // Todo: find new shortcut for jumping lists
        if (false) {
          e.preventDefault();
          setFocus({ col: dayIdx - 1, item: index });
        }
        break;
      case "ArrowRight":
        if (e.metaKey && e.shiftKey) {
          e.preventDefault();
          // Nav to next week
          setWeeksOffset(weeksOffset + 1);
        }
        // Todo: find new shortcut for jumping lists
        if (false) {
          e.preventDefault();
          setFocus({ col: dayIdx + 1, item: index });
        }
        break;
      case "Escape":
        titleRef.current.blur();
        break;
      case "d":
        if (e.metaKey) {
          e.preventDefault();
          setShowItemView(true);
        }
        break;
      case "r":
        if (e.metaKey) {
          e.preventDefault();
          saveThenReload();
        }
        break;
      default:
        break;
    }
  };

  const removeItemRegular = () => {
    setWasRemoved(true);

    setTimeout(() => {
      handleDeleteItem(currentItem.id, index, dayIdx);
    }, deleteDuration);

    const jump = hasDividerAbove() ? 2 : 1;
    setFocus({
      col: dayIdx,
      item: index === 0 ? index : index - jump,
      stamp: new Date().getMilliseconds(),
    });
  };

  useEffect(() => {
    if (currentItem.title.length === 0 && titleRef && titleRef.current) {
      titleRef.current.focus();
    }
    // eslint-disable-next-line
  }, []);

  const [showItemView, setShowItemView] = useState(false);
  const onOpenItemView = () => {
    setShowItemView(true);
  };
  const onCloseItemView = () => {
    setShowItemView(false);
    setShowMenu(false);
  };

  const [showMenu, setShowMenu] = useState(false);
  const openMenu = (e) => {
    e.stopPropagation();
    saveItemToDB();
    setShowMenu(true);
  };

  const [straightToTime, setStraightToTime] = useState(false);

  const closeMenu = (e) => {
    if (e) e.stopPropagation();
    setStraightToTime(false);
    setShowMenu(false);
  };

  const [playActive] = useSound(PopDownSFX, { volume: 0.15 });
  const [playOn] = useSound(PopUpOnSFX, { volume: 0.1 });
  const [playOff] = useSound(PopUpOffSFX, { volume: 0.04 });

  const playCheckingStaggered = () => {
    if (!sfxOn) return;
    playActive();
    setTimeout(() => {
      checked ? playOff() : playOn();
    }, 60);
  };

  const [checked, setChecked] = useState(currentItem.completed);

  const checkCheckbox = () => {
    handleUpdateItem(
      currentItem,
      index,
      dayIdx,
      title,
      description,
      !checked,
      currentItem.time
    );
    setChecked((prev) => !prev);
  };

  const handleGripperMouseDown = (e) => {
    titleRef.current.blur();
    e.stopPropagation();
  };

  const handleItemClick = (e) => {
    e.target.focus();
  };

  // for non input focus
  const handleItemKeyDown = (e) => {
    switch (e.key) {
      // case "Backspace":
      //   if (showMenu) return
      //   handleDeleteItem(currentItem.id, index, dayIdx)
      //   break;
      case "Enter":
        if (showMenu) {
          e.preventDefault();
          closeMenu(e);
        }
        titleRef.current.focus();
        break;
      default:
        break;
    }
  };

  const handleTimeClick = (e) => {
    if (showMissedUI) return;
    openMenu(e);
    setStraightToTime(true);
  };

  const [showMissedUI, setShowMissedUI] = useState(false);

  useEffect(() => {
    if (isBeforeCurrentDay && !currentItem.completed) {
      setShowMissedUI(true);
      return;
    }

    if (currentItem.completed) setShowMissedUI(false);
  }, [currentItem.completed, isBeforeCurrentDay]);

  const [descTooltipConfig, descTooltipTrigger] = useBoop(tooltipConfig);

  return (
    <div>
      <Draggable
        key={currentItem.id}
        draggableId={currentItem.id}
        index={index}
      >
        {(provided, snapshot) => {
          if (currentItem.isDivider) {
            return (
              <Divider
                provided={provided}
                isDragging={snapshot.isDragging}
                handleItemClick={handleItemClick}
                checkKeyDown={checkKeyDown}
                contrastDuringDragover={contrastDuringDragover}
              />
            );
          }

          return (
            <div
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              className={`
                item ${showMissedUI ? " tooltip-activator" : ""}
                significance-${currentItem.significance}
              `}
              data-missed={showMissedUI ? true : false}
              onClick={handleItemClick}
              onKeyDown={handleItemKeyDown}
              style={{
                boxShadow: snapshot.isDragging
                  ? "0 8px 40px rgba(0,0,0,.2)"
                  : "none",
                ...provided.draggableProps.style,
              }}
            >
              {wasRemoved && <Puffs duration={deleteDuration} />}
              <div className={`item-inner ${wasRemoved ? "removed" : ""}`}>
                <span
                  className={`btn-subtle gripper ${!title ? "invsible" : ""}`}
                  onMouseDown={handleGripperMouseDown}
                >
                  <GripperSVG size={8} fill="#B7BAC1" missed={showMissedUI} />
                </span>

                <span
                  className="btn-subtle checkbox"
                  data-checked={checked}
                  onMouseDown={() => {
                    if (sfxOn) playActive();
                    checkCheckbox();
                  }}
                  onMouseUp={() => {
                    if (!sfxOn) return;
                    checked ? playOn() : playOff();
                  }}
                >
                  <CheckBox
                    checked={checked}
                    checkBoxStyle={{
                      checkedColor: "rgb(62, 100, 255)",
                      size: 15,
                      unCheckedColor: title.length > 0 ? "#172b4d" : "#B7BAC1",
                    }}
                    style={{ border: "1px solid transparent" }}
                    duration={120}
                  />
                </span>

                <div className="text-holster">
                  <TextareaAutosize
                    value={title}
                    ref={titleRef}
                    tabIndex={dayIdx * 20 + index + 1}
                    onChange={handleChangeTitle}
                    onBlur={saveItemToDB}
                    onKeyDown={checkKeyDown}
                    placeholder="Add item..."
                    className={`item-title ${checked ? "checked" : ""}`}
                  ></TextareaAutosize>
                </div>

                <div className="peripherals-holster">
                  {displayTime && (
                    <span
                      className={`btn-subtle tooltip-activator time ${
                        showMissedUI ? "past" : ""
                      }`}
                      onClick={handleTimeClick}
                    >
                      {displayTime}
                      {!showMissedUI && (
                        <Tooltip align="bottom-right">Change Time</Tooltip>
                      )}
                    </span>
                  )}
                  {description && (
                    <span
                      className={`btn-subtle expander tooltip-activator ${
                        showMissedUI ? "past" : ""
                      }`}
                      onClick={onOpenItemView}
                      onMouseEnter={descTooltipTrigger}
                    >
                      <TextSVG
                        fill="#B7BAC1"
                        size={10}
                        isDragging={snapshot.isDragging}
                      />
                      {!showMissedUI && (
                        <Tooltip
                          config={descTooltipConfig}
                          align="bottom-right"
                        >
                          {description}
                        </Tooltip>
                      )}
                    </span>
                  )}
                </div>

                {title && (
                  <span
                    className="btn-subtle tooltip-activator ellipsis"
                    onClick={openMenu}
                    style={{ opacity: !snapshot.isDragging ? 1 : 0 }}
                  >
                    <EllipsisSVG
                      fill="#B7BAC1"
                      size={12}
                      isDragging={snapshot.isDragging}
                    />
                    {showMenu && (
                      <ItemMenu
                        title={title}
                        onOpenItemView={onOpenItemView}
                        checkCheckbox={checkCheckbox}
                        dayIdx={dayIdx}
                        description={currentItem.description}
                        closeMenu={closeMenu}
                        divideItem={divideItem}
                        removeItemRegular={removeItemRegular}
                        updateTime={updateTime}
                        time={currentItem.time}
                        straightToTime={straightToTime}
                        isComplete={currentItem.completed}
                        playCheckingStaggered={playCheckingStaggered}
                        showMenu={showMenu}
                        sendToToday={sendToToday}
                        significance={currentItem.significance}
                        updateSignificance={updateSignificance}
                      />
                    )}
                  </span>
                )}
                {showMenu && <span className="scrim" onClick={closeMenu} />}
              </div>
            </div>
          );
        }}
      </Draggable>
      <ItemView
        item={currentItem}
        handleChangeDescription={handleChangeDescription}
        handleChangeTitle={handleChangeTitle}
        description={description}
        title={title}
        onClose={onCloseItemView}
        show={showItemView}
        saveItemToDB={saveItemToDB}
        closeTimeoutMS={500}
      />
    </div>
  );
};

export default Item