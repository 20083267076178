import React, { useState, useEffect } from 'react';
import { itemsByCreatorByDate } from "../graphql/queries";
import moment from 'moment';
import { API, graphqlOperation } from "aws-amplify";
import SkeletonItemsLoader from './SkeletonItemsLoader';
import { hydrationMonths } from '../helpers/data';
import sortYear from '../helpers/sortYear';

const YearView = ({
  user,
  yearOffset,
}) => {
  const [items, setItems] = useState([]);
  const [arrayedMonths, setArrayedMonths] = useState([...hydrationMonths]);
  const [yearInView, setYearInView] = useState([null, null]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const yearStart = moment().startOf("year").add(yearOffset, "years");
    const yearEnd = moment().endOf("year").add(yearOffset, "years");

    setYearInView([yearStart, yearEnd]);
  }, [yearOffset]);

  useEffect(() => {
    fetchYear(yearInView[0], yearInView[1]);
    // eslint-disable-next-line
  }, [yearInView]);

  const fetchYear = async (startDate, endDate) => {
    if (!startDate || !user || arrayedMonths[0].items.length !== 0) return;
    setIsLoading(true);

    try {
      const itemData = await API.graphql(
        graphqlOperation(itemsByCreatorByDate, {
          creator: user.username,
          date: {
            between: [startDate, endDate],
          },
          limit: 1000,
          filter: {
            significance: { ge: 2 }, // greater than or equal to
          },
        })
      );

      const itemList = itemData.data.itemsByCreatorByDate.items;
      setItems(itemList);
      setIsLoading(false);

    } catch (error) {
      console.info("ERROR fetching: ", error);
    }
  };

  useEffect(() => {
    setArrayedMonths(sortYear(items, arrayedMonths));
    // eslint-disable-next-line
  }, [items])

  if (isLoading) {
    return (
      <div className="col-wrapper">
        <h2 className="col-header">
          <span className="dow-text" style={{ color: "#172b4d" }}>
            {yearInView[0]?.format("YYYY")}
          </span>
        </h2>
        <SkeletonItemsLoader />
      </div>
    );
  }

  return (
    <div>
      <div className="row date-display">
        <h2 className="page-header">
          {yearInView[0]?.format("YYYY")}
        </h2>
      </div>

      <div className="flex" style={{ flexDirection: "column" }}>
        <div style={{ marginBottom: 28 }}>
          This view is a WIP. Mark items significance
          2 & 3 from Week View to see them here.
        </div>
      </div>

      <div className="row year-view">
        {arrayedMonths.map((month) => (
          <div className={`col-wrapper`} key={`${month.name}-monthwrap`}>
            <h2 className={`col-header`}>
              <span
                className="dow-text"
                style={{
                  color: "#172b4d",
                }}
              >
                {month.name}
              </span>
            </h2>
            <div className="col-list">
              {month.items.map((item) => {
                return (
                  <div
                    key={item.id}
                    className={`year-item flex space-between ${
                      item.significance === 3 ? "three" : ""
                    }`}
                  >
                    <span>{item.title}</span>
                    <span className="date">
                      {moment(item.date).format("Do")}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default YearView;
