import React from "react";
import Modal from "react-modal";
import { CloseSVG } from "../imgs/DynamicIcons";

Modal.setAppElement("#root");

const KeyboardModal = ({ show, onClose }) => {
  return (
    <Modal
      isOpen={show}
      onRequestClose={() => onClose()}
      className="modal"
      overlayClassName="overlay"
    >
      <div className="modal-closer">
        <CloseSVG />
      </div>
      <div className="splitter">
        <div className="half">
          <h2>Items</h2>

          <div className="shortcut-wrapper">
            <code>
              ⌘<span>+</span>
              <span className="word">Return</span>
            </code>
            <span>Complete Item</span>
          </div>

          <div className="shortcut-wrapper">
            <code>
              ⌘<span>+</span>
              <span className="word">Del</span>
            </code>
            <span>Delete item</span>
          </div>

          <div className="shortcut-wrapper">
            <code>
              ⌘<span>+</span>
              <span className="word">D</span>
            </code>
            <span>Open item detail view</span>
          </div>

          <div className="shortcut-wrapper">
            <code>
              ⌥<span>+</span>
              <span className="word">Down/Up Arrow</span>
            </code>
            <span>Change item order</span>
          </div>

          <div className="shortcut-wrapper">
            <code>
              ⌘<span>+</span>
              <span className="word">Down/Up Arrow</span>
            </code>
            <span>Move focus to next/previous item</span>
          </div>

          <div className="shortcut-wrapper">
            <code>
              ⌘<span>+</span>
              <span className="word">Shift</span>
              <span>+</span>
              <span className="word">Down/Up Arrow</span>
            </code>
            <span>Move focus to last/first item</span>
          </div>

          <div className="shortcut-wrapper">
            <code>
              ⌘<span>+</span>
              <span className="word">Shift</span>
              <span>+</span>
              <span className="word">Left/Right Arrow</span>
            </code>
            <span>Move focus to next/previous column</span>
          </div>
        </div>

        <div className="half">
          <h2>Detail View</h2>
          <div className="shortcut-wrapper">
            <code>
              ⌘<span>+</span>
              <span className="word">D</span>
            </code>
            <span>Save and close detail view</span>
          </div>

          <div className="shortcut-wrapper">
            <code>
              ⌘<span>+</span>
              <span className="word">Return</span>
            </code>
            <span>Save and close detail view</span>
          </div>

          <div className="shortcut-wrapper">
            <code>
              <span className="word">Escape</span>
            </code>
            <span>Save and close detail view</span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default KeyboardModal;
