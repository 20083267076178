import moment from "moment";

export const isCurrentDay = i => moment().startOf("week").add(i, "days").format("D") === moment().format("D");

export const isPastDay = i => moment().isAfter(moment().startOf("week").add(i, "days").endOf("day"));

export const getDay = (daysFromSunday) => {
  const startDay = moment().startOf("week");
  const thisDay = startDay.add(daysFromSunday, "days");

  return thisDay.format("MMM D");
};

export const convertTime12to24 = (time12h) => {
  const splitIndex = time12h.length - 2;
  const time = time12h.substring(0, splitIndex);
  const modifier = time12h.substring(splitIndex);

  let [hours, minutes] = time.split(":");

  minutes = !minutes ? '00' : minutes;

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM' || modifier === 'pm' || modifier === 'Pm') {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
}

export const convertTime24to12 = (time24h) => {
  let [hours, minutes] = time24h.split(":");
  
  let time = undefined;
  let amOrPm = 'am';
  if (hours >= 12) amOrPm = 'pm';
  hours = (hours % 12);
  if (hours === 0) {
    hours = 12;
  }
  time = `${hours}${minutes !== '00' ? ':' + minutes : ''} ${amOrPm}`;

  return time;
}