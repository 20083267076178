import React from "react"
import {animated} from 'react-spring'

const Tooltip = ({children, align, config}) => {
  return (
    <animated.span style={config} className={`tooltip-explicit truncate ${align}`}>
      {children}
    </animated.span>
  )
}

export default Tooltip;