import React, { useRef, useEffect } from "react";
import Modal from "react-modal";
import TextareaAutosize from "react-textarea-autosize";
import { CloseSVG } from "../imgs/DynamicIcons";

Modal.setAppElement("#root");

const ItemView = ({ 
  show,
  onClose,
  item,
  title,
  description,
  handleChangeTitle,
  handleChangeDescription,
  saveItemToDB
  }) => {

  const titleRef = useRef();
  const descriptionRef = useRef();

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        const { current } = descriptionRef;
        current.focus();
        current.selectionStart = current.selectionEnd = current.value.length;
      }, 0);
    }
  }, [show]);

  const saveToDB = () => {
    if (
      titleRef.current.value !== item.title
      || descriptionRef.current.value !== item.description
    ) {
      saveItemToDB()
    }
  };

  const handleClose = () => {
    saveToDB()
    onClose();
  };

  const checkKeyDown = (e) => {    
    if (e.metaKey) {
      if (e.key === "Enter" || e.key === "d") {
        e.preventDefault();
        handleClose();
      } else if (e.key === "r") {
        e.preventDefault();
        saveToDB();
        window.location.reload();
      }
      return
    }

    if (e.key === "Escape") {
      handleClose()
    }
  };

  return (
    <Modal
      isOpen={show}
      onRequestClose={handleClose}
      className="modal"
      overlayClassName="overlay"
    >
      <div
        className="modal-closer"
        onClick={handleClose}
      >
        <CloseSVG />
      </div>
      <div>
        <TextareaAutosize
          ref={titleRef}
          className="modal-title"
          value={title}
          onChange={e => handleChangeTitle(e.target.value)}
          onKeyDown={checkKeyDown}
          placeholder="Title..."
        />
      </div>
      <div>
        <textarea
          ref={descriptionRef}
          className="modal-description"
          value={description}
          onChange={e => handleChangeDescription(e.target.value)}
          onKeyDown={checkKeyDown}
          placeholder="Notes..."
        />
      </div>
    </Modal>
  );
};

export default ItemView;
