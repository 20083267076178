import moment from "moment";

const momentNow = moment().startOf("week")

export const hydrationDays = [
  {
    date: moment(momentNow).format("YYYY-MM-DD"),
    name: "Sun",
    items: [],
  },
  {
    date: moment(momentNow).add(1, "days").format("YYYY-MM-DD"),
    name: "Mon",
    items: [],
  },
  {
    date: moment(momentNow).add(2, "days").format("YYYY-MM-DD"),
    name: "Tues",
    items: [],
  },
  {
    date: moment(momentNow).add(3, "days").format("YYYY-MM-DD"),
    name: "Weds",
    items: [],
  },
  {
    date: moment(momentNow).add(4, "days").format("YYYY-MM-DD"),
    name: "Thurs",
    items: [],
  },
  {
    date: moment(momentNow).add(5, "days").format("YYYY-MM-DD"),
    name: "Fri",
    items: [],
  },
  {
    date: moment(momentNow).add(6, "days").format("YYYY-MM-DD"),
    name: "Sat",
    items: [],
  },
];

export const hydrationMonths = [
  {
    name: "Jan",
    items: [],
  },
  {
    name: "Feb",
    items: [],
  },
  {
    name: "Mar",
    items: [],
  },
  {
    name: "Apr",
    items: [],
  },
  {
    name: "May",
    items: [],
  },
  {
    name: "Jun",
    items: [],
  },
  {
    name: "Jul",
    items: [],
  },
  {
    name: "Aug",
    items: [],
  },
  {
    name: "Sep",
    items: [],
  },
  {
    name: "Oct",
    items: [],
  },
  {
    name: "Nov",
    items: [],
  },
  {
    name: "Dec",
    items: [],
  },
];