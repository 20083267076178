import React, { useState, useEffect } from "react";
import Amplify, { Auth, Hub } from 'aws-amplify';
import awsconfig from "./aws-exports";
import Loader from 'react-loaders'
import Landing from './pages/Landing'
import ViewSelector from "./components/ViewSelector";
import './styles/App.scss'
import Logo from './imgs/arc-logo.png'

Amplify.configure(awsconfig);

function App() {
  if (process.env.NODE_ENV === 'development') {
    const favicon = document.getElementById('favicon')
    favicon.href = "favicon-dev.ico"
  }

  const initialFormState = {
    username: '', password: '', email: '', authCode: '', formType: 'landing'
  }

  const [formState, setFormState] = useState(initialFormState)
  const [user, setUser] = useState(null)
  const [isCheckingUser, setIsCheckingUser] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    checkUser();
    setAuthListener();
    // eslint-disable-next-line
  }, [])

  const setAuthListener = async () => {
    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signOut':
          console.log('user signed out');
          setFormState(() => ({...formState, formType: "landing"}))
          break;
        case 'default':
          break;
        default:
          break;
      }
    })
  }

  const onFormChange = (e) => {
    e.persist()
    if (errorMessage) setErrorMessage('')
    setFormState(() => ({...formState, [e.target.name]: e.target.value}))
  }

  const checkUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      setUser(user)
      setFormState(() => ({...formState, formType: "signedIn"}))
      setIsCheckingUser(false)
    } catch (error) {
      setFormState(() => ({...formState, formType: "landing"}))
      setIsCheckingUser(false)
    }
  }

  const signUp = async (e) => {
    e.preventDefault()
    const { username, email, password} = formState
    if (username === '' || email === '' || password === '') return

    if (password.length < 6) {
       setErrorMessage('Password must be more than 6 characters')
      return
    }

    setIsSubmitting(true)
    try {
      await Auth.signUp({ username, password, attributes: {email}})
      setFormState(() => ({...formState, formType: "confirmSignUp"}))
    } catch (e) {
      setErrorMessage(e.message)
    }
    setIsSubmitting(false)
  }

  const confirmSignUp = async (e) => {
    e.preventDefault()

    const { username, authCode} = formState
    if (username === '' || authCode === '') return

    setIsSubmitting(true)
    try {
      await Auth.confirmSignUp( username, authCode)
      setFormState(() => ({...formState, username: username, formType: "signIn"}))
    } catch (e) {
      setErrorMessage(e.message)
    }
    setIsSubmitting(false)
  }

  const signIn = async (e) => {
    e.preventDefault()
    const { username, password} = formState
    if (username === '' || password === '') return

    setIsSubmitting(true)
    try {
      const user = await Auth.signIn( username, password)
      setUser(user)
      setFormState(() => ({...formState, formType: "signedIn"}))
    } catch (e) {
      setErrorMessage(e.message)
    }
    setIsSubmitting(false)
  }

  const signOut = () => {
    Auth.signOut()
  }

  // Avoid landing page flash during async authenticated user check
  if (isCheckingUser) return <></>

  if (formState.formType === "landing") {
    return <Landing formState={formState} setFormState={setFormState} />
  }

  if (formState.formType === "signedIn") {
    return <ViewSelector signOut={signOut} user={user} />;
  }

  return (
    <div className="App form-page">
      <div className={`form ${errorMessage ? 'form-shake' : ''}`}>
        <div className="form-header">
          <img src={Logo} alt="Arc-logo"/>
        </div>
        <div className="form-internal">
        {
          formState.formType === "signUp" && (
            <div>
              <h2>Let's get started</h2>

              {errorMessage &&
              <div className="form-error">
                {errorMessage}
              </div>}

              <form onSubmit={signUp}>
                <label htmlFor="email">Email (used to activate account)</label>
                <input name="email" placeholder="your@email.com" type="email" onChange={onFormChange} autoFocus={true}/>

                <label htmlFor="username">Pick a username</label>
                <input name="username" placeholder="billieeilish" onChange={onFormChange}/>

                <label htmlFor="password">Password</label>
                <input name="password" placeholder="Remember this!" type="password" onChange={onFormChange}/>
                
                <button type="submit" className="btn-primary">
                  {isSubmitting 
                    ? <Loader type="ball-pulse" />
                    : 'Sign up'}
                </button>
              </form>

              <div className="form-footer">
                Have an account? 
                <button onClick={() => {
                  setFormState({...formState, formType: "signIn"})
                }}>Sign In</button>
              </div>
            </div>
          )
        }
        {
          formState.formType === "confirmSignUp" && (
            <form onSubmit={confirmSignUp}>
              <h2>Check code</h2>

              {errorMessage &&
              <div className="form-error">
                {errorMessage}
              </div>}

              <p className="subtitle">(It's in your inbox)</p>
              <input name="authCode" placeholder="Enter your code" onChange={onFormChange} autoFocus={true}/>

              <button type="submit" className="btn-primary">
                {isSubmitting 
                    ? <Loader type="ball-pulse" />
                    : 'Finish sign up'}
              </button>
            </form>
          )
        }
        {
          formState.formType === "signIn" && (
            <div>
              <h2>Sign in</h2>

              {errorMessage &&
              <div className="form-error">
                {errorMessage}
              </div>}

              <form onSubmit={signIn}>
                <label htmlFor="username">Username</label>
                <input value={formState.username} name="username" placeholder="username" onChange={onFormChange} autoFocus={true}/>
                
                <label htmlFor="password">Password</label>
                <input name="password" placeholder="password" type="password" onChange={onFormChange}/>
                <button type="submit" className="btn-primary">
                  {isSubmitting 
                    ? <Loader type="ball-pulse" />
                    : 'Sign in'}
                </button>
              </form>

              <div className="form-footer">
                No Account? 
                <button onClick={() => {
                  setFormState({...formState, formType: "signUp"})
                }}>Create Account</button>
              </div>
            </div>
          )
        }
        </div>
      </div>
    </div>
  )
}

export default App;